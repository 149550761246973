import Button from '@uikit/components/Button/Button';
import { ButtonSize, ButtonWidth } from '@uikit/components/Button/consts';
import { ClickableAction } from '@uikit/components/Clickable/consts';
import clsx from 'clsx';
import Clickable from '../Clickable/Clickable';
import RegioBadge from '../RegioBadge/RegioBadge';
import styles from './RegioTariffComparison.module.scss';
import RegioTariffComparisonBadge from './RegioTariffComparisonBadge/RegioTariffComparisonBadge';
import RegioTariffComparisonCard from './RegioTariffComparisonCard/RegioTariffComparisonCard';
import RegioTariffComparisonPrice from './RegioTariffComparisonPrice/RegioTariffComparisonPrice';
import RegioTariffComparisonProviderName from './RegioTariffComparisonProviderName/RegioTariffComparisonProviderName';
import RegioTariffComparisonTariffName from './RegioTariffComparisonTariffName/RegioTariffComparisonTariffName';
import type { RegioTariffComparisonProps } from './interfaces';

const RegioTariffComparison = ({
  baseProviderTariff,
  enercityTariff,
  savingsAmount,
  configuratorPath,
  handleOnClickDetails,
}: RegioTariffComparisonProps) => {
  const badgeText = `Sie sparen **${savingsAmount}** jährlich*`;

  return (
    <div className={styles.base}>
      <RegioTariffComparisonCard showLogo>
        <div className={clsx(styles.badge, styles.showOnMobile)}>
          <RegioBadge>{badgeText}</RegioBadge>
        </div>
        <RegioTariffComparisonTariffName>
          {enercityTariff.tariffName}
        </RegioTariffComparisonTariffName>
        <RegioTariffComparisonPrice>
          {enercityTariff.price}
        </RegioTariffComparisonPrice>
        <Clickable
          className={styles.detailLink}
          actionType={ClickableAction.Custom}
          onClick={handleOnClickDetails}
        >
          Tarifdetails anzeigen
        </Clickable>
        <Button
          actionType={ClickableAction.Linkextern}
          linkextern={configuratorPath}
          className={clsx(styles.button, styles.hideOnMobile)}
          width={ButtonWidth.Full}
          size={ButtonSize.Large}
        >
          Jetzt sparen
        </Button>
      </RegioTariffComparisonCard>
      <RegioTariffComparisonBadge />
      <RegioTariffComparisonCard size="small">
        <div className={clsx(styles.badge, styles.hideOnMobile)}>
          <RegioBadge>{badgeText}</RegioBadge>
        </div>
        <RegioTariffComparisonProviderName>
          {baseProviderTariff.providerName}
        </RegioTariffComparisonProviderName>
        <RegioTariffComparisonTariffName>
          {baseProviderTariff.tariffName}
        </RegioTariffComparisonTariffName>
        <RegioTariffComparisonPrice small>
          {baseProviderTariff.price}
        </RegioTariffComparisonPrice>
      </RegioTariffComparisonCard>
      <Button
        actionType={ClickableAction.Linkintern}
        linkintern="/privatkunden/strom/tarife"
        className={clsx(styles.button, styles.showOnMobile)}
        width={ButtonWidth.Full}
        size={ButtonSize.Medium}
      >
        Jetzt sparen
      </Button>
    </div>
  );
};

export default RegioTariffComparison;
