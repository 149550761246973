import clsx from 'clsx';
import { extractClickableProps } from '../../helpers/extractClickableProps';
import Clickable from '../Clickable/Clickable';
import LegacyPicture from '../LegacyPicture/LegacyPicture';
import { LegacyPictureProps } from '../LegacyPicture/interfaces';
import styles from './Image.module.scss';
import type { ImageProps } from './interfaces';

const Image = ({
  image: asset,
  arFile,
  caption,
  className,
  shadow = false,
  isLink = false,
  title,
  fitCover,
  disableLazyLoad,
  scaleToFit,
  align,
  breakpointAspectRatioConfigs,
  ...props
}: ImageProps) => {
  const classes = clsx(styles.base, className);

  const clickableProps = extractClickableProps(props);

  const width = scaleToFit
    ? '100%'
    : !fitCover
    ? `${asset?.dimensions?.width}px`
    : '';

  const pictureProps: LegacyPictureProps = {
    asset,
    className: clsx({
      [styles.shadow]: shadow,
    }),
    fitCover,
    disableLazyLoad,
    scaleToFit,
    arFile,
    breakpointAspectRatioConfigs,
  };

  return (
    <figure
      title={title}
      className={clsx(classes, align && styles[align])}
      style={{
        width,
      }}
    >
      {isLink && !pictureProps.arFile ? (
        <Clickable className={styles.clickable} {...clickableProps}>
          <LegacyPicture {...pictureProps} />
        </Clickable>
      ) : (
        <LegacyPicture {...pictureProps} />
      )}

      {caption && <figcaption>{caption}</figcaption>}
    </figure>
  );
};
export default Image;
